import { useMemo } from "react"
import { motion } from "framer-motion"

import { Badge, Box, Flex, Skeleton as ChakraSkeleton, Text, useColorMode } from "@chakra-ui/react"
import gradient from "random-gradient"
import { formatCurrency } from "utils/format"
import { capitalizeFirstLetter } from "utils/string"

import Image from "app/core/components/Image"
import SubDueDateIndicator from "app/subs/components/SubDueDateIndicator"

const AnimatedFlex = motion(Flex)

export const Skeleton = () => (
  <Flex sx={{ alignItems: "center", justifyContent: "space-between", py: 3 }}>
    <ChakraSkeleton
      sx={{
        width: 65,
        minWidth: 65,
        height: 65,
        borderRadius: "50%",
      }}
    />

    <Box sx={{ flex: 1 }}>
      <ChakraSkeleton
        sx={{
          mx: 3,
          height: 4,
          borderRadius: 9999,
          width: "40%",
        }}
      />
    </Box>

    <ChakraSkeleton
      sx={{
        mx: 3,
        height: 4,
        borderRadius: 9999,
        width: "10%",
        maxWidth: 120,
      }}
    />
  </Flex>
)

type SubRowProps = {
  imageDimension?: number
  textStyle?: any
  verticalAlignment?: boolean
  sub: any
}

const SubRow: React.FC<SubRowProps> = ({
  imageDimension = 65,
  textStyle = {},
  verticalAlignment = false,
  sub = {},
  children,
}) => {
  const { colorMode } = useColorMode()
  const { id, name, amount, currency, cycleUnit, cycleUnitNb, product, startingAt, endingAt } = sub

  const isInactive = useMemo(
    () => (endingAt ? new Date(endingAt).getTime() < new Date().getTime() : false),
    [endingAt]
  )

  return (
    <AnimatedFlex
      whileHover={{ transition: { duration: 1 } }}
      whileTap={{ scale: 0.95 }}
      sx={{
        flex: 1,
        overflow: "hidden",
        alignItems: "center",
        justifyContent: "space-between",
        py: 3,
        transition: "all .2s ease-in-out",
        ":hover": {
          cursor: "pointer",
          transform: "scale(0.98)",
          opacity: 0.75,
        },
      }}
    >
      <Flex
        className="sub-logo"
        sx={{
          position: "relative",
          alignItems: "center",
          justifyContent: "center",
          width: "60px",
          height: "60px",
          overflow: "hidden",
          borderRadius: 20,
          ...(colorMode === "dark" ? { border: "1px solid rgba(121, 121, 121, 0.52)" } : {}),
        }}
      >
        {product?.logoUrl ? (
          <>
            <img
              style={{
                position: "absolute",
                filter: "blur(20px)",
                opacity: 0.6,
                borderRadius: 9999,
              }}
              alt={`${product.name} icon`}
              src={product?.logoUrl}
            />
            <Image
              borderRadius="full"
              alt={`${product.name} icon`}
              src={product?.logoUrl}
              dimensions={[36, 36]}
              objectFit="cover"
            />
          </>
        ) : (
          <>
            <Flex
              sx={{
                position: "absolute",
                transform: "scale(2.2) rotate(0.01)",
                filter: "blur(20px)",
                opacity: 0.6,
                background: gradient(`${id}`),
                width: "36px",
                height: "36px",
              }}
            />
            <Flex
              sx={{
                background: gradient(`${id}`),
                width: "36px",
                height: "36px",
                borderRadius: 99999,
              }}
            />
          </>
        )}
      </Flex>

      {verticalAlignment ? (
        <Flex
          sx={{
            flex: 1,
            flexDirection: "column",
            pl: 3,
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
        >
          <Text
            pb={1}
            {...(isInactive ? { color: "gray.500" } : {})}
            sx={{
              flex: 1,
              fontSize: 18,
              fontWeight: "bold",
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
              ...textStyle,
            }}
          >
            {capitalizeFirstLetter(name)}
            {isInactive && (
              <Badge ml={2} sx={{ fontSize: 11, py: 1, px: 2 }}>
                Inactive
              </Badge>
            )}
          </Text>

          <Text pt={1} sx={{ fontSize: 14, fontWeight: "bold", ...textStyle }}>
            {formatCurrency(amount, { currency })}
            <Text as="span" color="gray.400" sx={{ fontSize: 14 }}>
              {" "}
              / {cycleUnit}
            </Text>
          </Text>
        </Flex>
      ) : (
        <>
          <Flex
            sx={{
              flex: 1,
              flexDirection: "column",
              pl: 3,
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            <Text
              pb={1}
              {...(isInactive ? { color: "gray.500" } : {})}
              sx={{
                flex: 1,
                fontSize: 18,
                fontWeight: "bold",
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
                ...textStyle,
              }}
            >
              {capitalizeFirstLetter(name)}
              {isInactive && (
                <Badge ml={2} sx={{ fontSize: 11, py: 1, px: 2 }}>
                  Inactive
                </Badge>
              )}
            </Text>
            {!isInactive && startingAt && (
              <SubDueDateIndicator
                startAt={startingAt}
                endAt={endingAt}
                interval={cycleUnitNb}
                frequency={cycleUnit}
              />
            )}
          </Flex>

          <Text sx={{ fontSize: 18, fontWeight: "bold", ...textStyle }}>
            {formatCurrency(amount, { currency })}
          </Text>
        </>
      )}

      {children}
      <style jsx>
        {`
          .sub-logo {
            // Safari border-radius + overflow: hidden + CSS transform fix avoiding flickering
            -webkit-mask-image: -webkit-radial-gradient(white, black);
          }
        `}
      </style>
    </AnimatedFlex>
  )
}

export default SubRow
