import { motion } from "framer-motion"
import { IconButton } from "@chakra-ui/react"

const MotionIconButton = motion(IconButton)

export function AnimatedIconButton(props) {
  return (
    <MotionIconButton
      whileHover={{
        scale: 0.9,
        transition: { duration: 0.1 },
      }}
      whileTap={{ scale: 0.85 }}
      {...props}
    />
  )
}
