type Options = {
  locale?: string | string[] | undefined
  currency?: string
  minimumFractionDigits?: number
}

export const formatCurrency = (number, opts: Options = {}) => {
  const defaultLocale =
    typeof window !== "undefined"
      ? navigator?.language
      : Intl.DateTimeFormat().resolvedOptions().locale
  const { locale = defaultLocale, currency = "USD", minimumFractionDigits = 2 } = opts
  const res = Intl.NumberFormat(locale, {
    style: "currency",
    currency,
    minimumFractionDigits,
  }).format(number)

  return res
}

export const formatDate = (date) => {
  const defaultLocale =
    typeof window !== "undefined"
      ? navigator?.language
      : Intl.DateTimeFormat().resolvedOptions().locale

  return new Intl.DateTimeFormat(defaultLocale).format(date)
}
