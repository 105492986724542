import { useQuery } from "blitz"
import { Box, Flex, Text } from "@chakra-ui/react"
import TimeAgo from "javascript-time-ago"
import ReactTimeAgo from "react-time-ago"
import en from "javascript-time-ago/locale/en"

import getNextDueDate from "app/subs/queries/getNextDueDate"

const frequencyByCycleUnit = {
  week: "WEEKLY",
  month: "MONTHLY",
  year: "YEARLY",
}

TimeAgo.addLocale(en)

type SubDueDateIndicatorProps = {
  startAt: string
  endAt?: string
  frequency?: string
  interval?: number
  textColor?: string
}

const SubDueDateIndicator: React.FC<SubDueDateIndicatorProps> = ({
  startAt,
  endAt,
  interval,
  frequency: cycleUnit,
  textColor,
}) => {
  const [{ date: nextDueDate, diffDays }]: any = useQuery(getNextDueDate, {
    startAt: startAt ? new Date(startAt) : undefined,
    endAt: endAt ? new Date(endAt) : undefined,
    frequency: cycleUnit ? frequencyByCycleUnit[cycleUnit] : undefined,
    interval,
  })

  const getDueDateIndicatorBg = () => {
    if (diffDays < 10) {
      return "linear-gradient(90deg, rgba(255,0,0,1) 0%, rgba(255,89,159,1) 100%)"
    }

    if (diffDays < 20) {
      return "linear-gradient(90deg, rgba(255,146,17,1) 0%, rgba(255,245,17,1) 100%)"
    }

    return "linear-gradient(90deg, rgba(41,189,74,1) 0%, rgba(0,255,148,1) 100%)"
  }

  return (
    <Flex sx={{ alignItems: "center", fontSize: 14 }}>
      <Box
        mr={2}
        sx={{
          height: "14px",
          width: "14px",
          background: getDueDateIndicatorBg(),
          borderRadius: "50%",
        }}
      />
      <Text sx={textColor ? { color: textColor } : {}}>
        Due {nextDueDate && <ReactTimeAgo future date={nextDueDate} />}
      </Text>
    </Flex>
  )
}

export default SubDueDateIndicator
